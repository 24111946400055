import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({
		id: window.localStorage.getItem('id'),
		role: window.localStorage.getItem('role'),
		email: window.localStorage.getItem('email'),
		hubToken: window.localStorage.getItem('hubToken'),
		is_admin: window.localStorage.getItem('is_admin')
	});

	return (
		<UserContext.Provider value={[user, setUser]}>
			{children}
		</UserContext.Provider>
	);
};

UserProvider.propTypes = {
	children: PropTypes.object,
};
