import React from 'react';
import { ContainerLoaderDiv, LoaderImg } from './styles';
import loader from '../../assets/loader.svg';

const Loader = ({ marginTop, marginLeft }) => {
	return (
		<ContainerLoaderDiv marginTop={marginTop} marginLeft={marginLeft}>
			<LoaderImg src={loader} alt="loader" />
		</ContainerLoaderDiv>
	)
};

export default Loader;
