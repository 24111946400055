/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, createContext, useEffect } from 'react';

export const PlatformContext = createContext();

export const PlatFormProvider = (props) => {
	const [platform, setPlatform] = useState('desktop');
	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width < 600) {
				return setPlatform('mobile');
			}
			if (width >= 600 && width < 800) {
				return setPlatform('tablet');
			}
			return setPlatform('desktop');
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return (
		<PlatformContext.Provider value={[platform, setPlatform]}>
			{props.children}
		</PlatformContext.Provider>
	);
};
