/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import axios from 'axios';
import config from '../../../config/endpoints.json';
import API from '../globalActions/API';

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';

const baseUrl = config[env].baseAPI;

axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('hubToken')}`;

export const sentBack = async ({ SKU }, { inputs = {} }) => {
    if (!inputs.tracking_number) { return }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const res = await axios.post(`${baseUrl}/hub/products/${SKU}/sent_back`, { tracking_number: inputs.tracking_number }, { cancelToken: source.token });
    source.cancel()
    if (res.status === 200) {
        return { message: `✅ Le colis ${inputs.tracking_number} a bien été renvoyé` }
    }
    return false;
};

export const getExpeditions = async ({last_id, elementToSearch, sort}) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const res = await axios.get(`${baseUrl}/hub/expeditions/${last_id}?sort=${sort}${elementToSearch ? `&tracking_number_to_search=${elementToSearch}` : ''}`, { cancelToken: source.token });
    source.cancel ()
    if (res.status === 200) {
        return res.data
    }
    return API.clearStorage();
}

export const expeditionRetrieved = async (relatedInfo) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const res = await axios.post(`${baseUrl}/hub/expeditions/retrieved/${relatedInfo.name}`, {}, { cancelToken: source.token })
    source.cancel()
    if (res.status === 200) {
        return { message: `✅ Le colis ${relatedInfo.name} a bien été receptionné` }
    }
    return false;
}

export const expeditionUnRetrieved = async (relatedInfo) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const res = await axios.post(`${baseUrl}/hub/expeditions/un_retrieved/${relatedInfo.name}`, {}, { cancelToken: source.token })
    source.cancel ()
    if (res.status === 200) {
        return { message: 'L\'information a bien été remontée' }
    }
    return false;
}

// export const sentBackProducts = async (skus) => {
//     try {
//         await Promise.all(
//             skus.map((sku) => sentBack({ SKU: sku.SKU }))
//         )
//     } catch (error) {
//         console.error(error);
//         return error;
//     }
// }

export const generateListing = async ({ list_of_skus } = { }) => {
    if (!list_of_skus) return;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    try {
        const { data } = await axios.post(`${baseUrl}/hub/expeditions/generate_listing`, { list_of_skus }, { cancelToken: source.token })
        if (!data) { return { data: null, source }}

        const hiddenElement = document.createElement('a');

        hiddenElement.href = data.listing;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'listing.pdf';
        hiddenElement.click();


        hiddenElement.remove()

        return { data, source }
    } catch (e) {
        return { data: null, source }
    }

}

export const generateExpeditions = async ({ list_of_skus, recipient } = {}) => {
    if (!list_of_skus) return;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const { data } = await axios.post(`${baseUrl}/hub/expeditions/partner_expedition`, { list_of_skus, recipient }, { cancelToken: source.token })
    if (!data) { return { data: null, source }}

    const hiddenElement = document.createElement('a');

	hiddenElement.href = data.link;
	hiddenElement.target = '_blank';
	hiddenElement.download = data.filename;
    hiddenElement.click();

    // hiddenElement.href = data.listing;
    // hiddenElement.target = '_blank';
    // hiddenElement.download = 'listing.pdf';
    // hiddenElement.click();


    hiddenElement.remove()

    return { data, source }
}

export default {
    sentBack,
    generateExpeditions,
    generateListing,
    getExpeditions,
    expeditionRetrieved,
    expeditionUnRetrieved
    // sentBackProducts
};
