import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Nav, Tag } from 'tabler-react'
import { Link } from 'react-router-dom'
// import API from '../../controllers/actions/globalActions/API';
import { PlatformContext } from '../contexts/PlatformContext';
import { UserContext } from '../../controllers/jwt/getHubberData';
import menuSchema from './menuSchema';
import {
	StyledContainerNavMenu,
	Li
} from './style';

const NavMenu = ({ page, type, isLoaded }) => {
	const [platform] = useContext(PlatformContext);
	const [user] = useContext(UserContext);
	// const [numberProducts, setNumberProducts] = useState([]);

	// const getProducts = async () => {
	// 	const allSubMenu = menuSchema.map (item => item.subMenu).filter(Boolean)
	// 	const sortKeys = menuSchema.concat(...allSubMenu).filter(item => {
	// 		if (!item.displayNumber) return false
	// 		if (item.blackList && item.blackList.includes(user.id / 1)) return false
	// 		if (item.whiteList && !item.whiteList.includes(user.id / 1)) return false
	// 		return true
	// 	}).map(i => i.url.replace('/', ''))

	// 	if (!sortKeys.length) return
	// 	const counters = await API.getNumberProducts({ sortKeys });
	// 	return setNumberProducts(Array.isArray(counters) && counters || []);
	// }

	// useEffect(() => {
	// 	getProducts();
	// }, []);

	const generateMenu = (array) => {
		const arrToReturn = [];
		array.map((item) => {
			if (item.blackList && item.blackList.includes(user.id / 1)) { return null; }
			if (item.restricted && !user.is_admin) { return null; }
			if (item.whiteList && !item.whiteList.includes(user.id / 1)) { return null; }
			return (
				arrToReturn.push(
					<Li key={item.id} flex={!item.subMenu}>
						<Link to={item.url} style={{ textDecoration: 'none', color: 'inherit'}}>
							<div style={{ display: 'flex', alignItems: 'center', width: '170px' }}>
								<Nav.Item active={item.url === `/${page}` || item.url.includes('inventor') && page.includes('inventor')} type="div" className="nav-item-navmenu">
									{item.title}
								</Nav.Item>
								{/* {item.displayNumber && item.type === type && isLoaded ? (
									<Tag style={{ height: '25px' }}>{itemsLength}</Tag>
									) : null} */}
							</div>
						</Link>
						{item.subMenu && generateMenu(item.subMenu)}
					</Li>
				)
			);
		}, [menuSchema]);
		return (
			<Nav>
				{arrToReturn}
			</Nav>
		);
	}

	return (
		<StyledContainerNavMenu show={platform === 'desktop'}>
			{generateMenu(menuSchema)}
		</StyledContainerNavMenu>
	);
};

NavMenu.propTypes = {
	page: PropTypes.string
};

export default React.memo(NavMenu);
