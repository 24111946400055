/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyleContainerNavBar = styled.div`
	position: sticky;
	height: 55px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: solid 1px ${(props) => props.theme.veryDarkGrey};
	background-color: ${(props) => props.theme.whiteColor};
`;
