/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, useContext, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './globalStyles';
import Fonts from './Fonts';
import { UserContext } from './controllers/jwt/getHubberData';
import "tabler-react/dist/Tabler.css";
import GenerateExpeditions from './pages/generateExpeditions';
import Loader from './components/loader';

const Login = lazy(() => import('./pages/login'));
const Account = lazy(() => import('./pages/account'));
const Invoices = lazy(() => import('./pages/invoices'));
const DisplayProducts = lazy(() => import('./pages/displayProducts'));
const DisplayADNSells = lazy(() => import('./pages/displayADNSells'));
const DisplayExpeditions = lazy(() => import('./pages/displayExpeditions'));
const Product = lazy(() => import('./pages/product'));
const CreateInventory = lazy(() => import('./pages/createInventory'));

const theme = {
	whiteColor: '#ffffff',
	mainBackground: 'white',
	veryDarkGrey: '#979797',
	darkGrey: '#dfdfdf',
	lightGrey: '#f2f2f2',
	titleColor: '#373737',
	darkBlueColor: '#3a539b',
	mainFontFamily: 'Raleway',
	secondFontFamily: 'Lato',
	greenColorButton: '#3fc380',
	orangeColorButton: 'darkorange',
	darkOrangeButton: '#99401C',
	redColorButton: '#dc5941',
	blueColorButton: '#446cb3'
};

const App = () => {
	const [ready, setReady] = useState(false);
	const [userToDeconnect, setUserToDeconnect] = useState(false);
	const [user] = useContext(UserContext);

	useEffect(() => {
		Fonts();
		return setReady(true);
 	}, [user.id, user.role]);

	return ready && (
    <>
      <GlobalStyle
				background={theme.mainBackground}
      />
      <ThemeProvider theme={theme}>
			<Suspense
				fallback={
					<div style={{height: 'calc(100% - 50px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2em' }}>
						<Loader marginTop="105px" marginLeft="200px" />
					</div>}
			>
      	<Router>
      		<Switch>
      			<Route
      				path="/"
      				exact
      				render={() => {
      					return (
      						!userToDeconnect
      							? <DisplayExpeditions template="expeditions" setUserToDeconnect={setUserToDeconnect} />
										: <Redirect to="/login" />
      					);
      				}}
      			/>
						<Route
							path="/login"
							render={props => <Login setUserToDeconnect={setUserToDeconnect} {...props} />}
						/>
						<Route
								path="/signup"
								render={props => <Login setUserToDeconnect={setUserToDeconnect} {...props} />}
							/>
								<Route
								path="/password-forgotten"
								render={props => <Login setUserToDeconnect={setUserToDeconnect} {...props} />}
							/>
								<Route
								path="/confirmation-signup/:token"
								render={props => <Login setUserToDeconnect={setUserToDeconnect} {...props} />}
							/>
								<Route
								path="/change-password/:token"
								render={props => <Login setUserToDeconnect={setUserToDeconnect} {...props} />}
							/>
      			{/* <Route path="/login" exact component={Login}  />
						<Route path="/signup" exact component={Login}  />
      			<Route path="/password-forgotten" exact component={Login}  />
      			<Route path="/confirmation-signup/:token" exact component={Login}  />
      			<Route path="/change-password/:token" exact component={Login}  /> */}
      			<Route
      				path="/account"
      				exact
      				render={() => {
      					return (
      						!userToDeconnect
      							? <Account setUserToDeconnect={setUserToDeconnect} />
      							: <Redirect to="/login" />
      					);
      				}}
      			/>
      			<Route path="/invoices" exact component={Invoices} />

				<Route
      				path="/ventes"
      				exact
      				render={() => {
      				return (
								!userToDeconnect
      						? <DisplayADNSells template="ventes" setUserToDeconnect={setUserToDeconnect} />
      						: <Redirect to="/login" />
      				);
      			}}
      			/>
				<Route
      				path="/expeditions"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <DisplayExpeditions template="expeditions" setUserToDeconnect={setUserToDeconnect} />
      						: <Redirect to="/login" />
      					);
      				}}
				/>
					<Route
      				path="/inventories"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <CreateInventory template="inventories" setUserToDeconnect={setUserToDeconnect} />
      						: <Redirect to="/login" />
      					);
      				}}
				/>
				<Route
      				path="/create_inventory"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <CreateInventory template="create_inventory" setUserToDeconnect={setUserToDeconnect} />
      						: <Redirect to="/login" />
      					);
      				}}
				/>
						<Route
      				path="/listing_inventory/:order_id"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <CreateInventory template="listing_inventory" setUserToDeconnect={setUserToDeconnect} />
      						: <Redirect to="/login" />
      					);
      				}}
				/>
				<Route
      				path="/orders"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect ?
      						<DisplayProducts template="orders" setUserToDeconnect={setUserToDeconnect}  />
      						: <Redirect to="/login" />
      					);
      				}}
				/>
				<Route
					path="/to_send"
					exact
					render={() => {
					return (
						!userToDeconnect
							? <GenerateExpeditions template="to_send" setUserToDeconnect={setUserToDeconnect} />
							: <Redirect to="/login" />
						);
					}}
      			/>
				<Route
				path="/sent"
				exact
				render={() => {
				return (
					!userToDeconnect
						? <DisplayExpeditions template="sent" setUserToDeconnect={setUserToDeconnect} />
						: <Redirect to="/login" />
					);
				}}
      			/>
      			<Route
      				path="/arrivals"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <DisplayProducts template="arrivals" setUserToDeconnect={setUserToDeconnect} />
      						: <Redirect to="/login" />
      				);
      			}}
      			/>
				<Route
      				path="/all"
      				exact
      				render={() => {
						return (
								!userToDeconnect
									? <DisplayProducts template="all" setUserToDeconnect={setUserToDeconnect} />
								: <Redirect to="/login" />
						);
      				}}
				/>
      			<Route
      				path="/received"
      				exact
      				render={() => {
						return (
							!userToDeconnect
								? <DisplayProducts template="received" setUserToDeconnect={setUserToDeconnect} />
								: <Redirect to="/login" />
						);
      				}}
      			/>
				<Route
      				path="/to_diag"
      				exact
      				render={() => {
						return (
							!userToDeconnect
								? <DisplayProducts template="to_diag" setUserToDeconnect={setUserToDeconnect}  />
								: <Redirect to="/login" />
						);
      				}}
      			/>
				<Route
      				path="/to_sell_V0070_V0471"
      				exact
      				render={() => {
						return (
							!userToDeconnect
								? <DisplayProducts template="to_sell_V0070_V0471" setUserToDeconnect={setUserToDeconnect}  />
								: <Redirect to="/login" />
						);
      				}}
      			/>
				<Route
      				path="/to_sell_V0085_V0472"
      				exact
      				render={() => {
						return (
							!userToDeconnect
								? <DisplayProducts template="to_sell_V0085_V0472" setUserToDeconnect={setUserToDeconnect}  />
								: <Redirect to="/login" />
						);
      				}}
      			/>
      			<Route
      				path="/problems"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <DisplayProducts template="problems" setUserToDeconnect={setUserToDeconnect}  />
      						: <Redirect to="/login" />
      				);
      			}}
      			/>
      			<Route
      				path="/action_necessary"
      				exact
      				render={() => {
      				return (
      					!userToDeconnect
      						? <DisplayProducts template="action_necessary" setUserToDeconnect={setUserToDeconnect}  />
      						: <Redirect to="/login" />
      				);
      			}}
      			/>
						 <Route
      				path="/done"
      				exact
      				render={() => {
      				return (
      						!userToDeconnect
      						? <DisplayProducts template="done" setUserToDeconnect={setUserToDeconnect}  />
      						: <Redirect to="/login" />
      				);
      			}}
						 />
      			<Route
      				path="/product/:sku"
      				exact
      				render={(props) => {
      					return (
      						user.role
      							? <Product props={props} />
      							: <Redirect to="/login" />
      					);
      				}}
      			/>
      		</Switch>
      	</Router>
			</Suspense>
      </ThemeProvider>
    </>
	);
};

export default App;
