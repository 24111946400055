/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import axios from 'axios';
import { Parser } from 'json2csv';
import config from '../../../config/endpoints.json';
import { getExpeditions } from '../expeditionsActions/API.js';

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';

const baseUrl = config[env].baseAPI;

const clearStorage = () => {
	window.localStorage.removeItem('email');
	window.localStorage.removeItem('role');
	window.localStorage.removeItem('hubToken');
	window.localStorage.removeItem('id');
	window.localStorage.removeItem('is_admin');
	window.location.replace('/login');
};

const ping = async () => {
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.get(`${baseUrl}/ping`, { cancelToken: source.token });
	source.cancel()
	if (res) {
		return res.data;
	}
	return null;
};

const generateCSV = async ({ csv, fileName } = {}) => {
	// Generate a hidden link to download csv generated
	const hiddenElement = document.createElement('a');
	hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
	hiddenElement.target = '_blank';
	hiddenElement.download = `${fileName}.csv`;
	hiddenElement.click();
	return true;
};
const formatToCheops = ({ product, dossier }) => {
	return {
		SKU: product.SKU,
		dossier: dossier || '',
		lot: '',
		categorie: '0011',
		designation: `${product.model} | ${product.designation}`,
		prix_de_marché: product.floor_price,
		vente: product.sell_date,
		ordre: '',
		tva: product.tax
	};
};

const formatAdn = ({ product }) => {
	return {
		SKU: product.SKU,
		modèle: product.model,
		grade: product.grade,
		etat: product.fonctionnalite,
		'n° de suivi': product.tracking_number,
		'lien de suivi': product.tracking_url,
		// 'vente n°': product.sell_number,
		'vente du': product.sell_date,
		réserve: product.floor_price,
		remarque: product.remark,
		tva: product.tax,
		'prix de réserve': product.wanted_amount
	};
};

const jsonToCsv = async (relatedInfo, { jsonToParse, fields, shouldDownload, cheopsFormat, inputs = {} } = { }) => {
	delete relatedInfo.buttonClicked;
	if (!Object.keys(relatedInfo).length) { return; }

	relatedInfo = Object.entries(relatedInfo).reduce((total, [index, product]) => {
		total.push(
			cheopsFormat
				? formatToCheops({ product, dossier: inputs.dossier })
				: formatAdn({ product })
		);
		return total;
	}, []);
	const json2csvParser = new Parser({ fields: fields || Object.keys(relatedInfo[0]) });
	const csv = await json2csvParser.parse(jsonToParse || relatedInfo);
	if (shouldDownload) { return generateCSV({ csv, fileName: cheopsFormat ? 'cheops' : 'products' }); }
	return csv;
};

const getProducts = async ({ type, lastSku, elementToSearch, sort } = {} ) => {
	try {
		const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
		// const res = await axios.get(`${baseUrl}/hub/products/status/${type}${count ? '?count=true' : ''}`, { cancelToken: source.token });
		const res = await axios.get(`${baseUrl}/hub/products/status/${type}/${lastSku}?sort=${sort}${elementToSearch ? `&sku_to_search=${elementToSearch}` : ''}`, { cancelToken: source.token });

		source.cancel ()
		if (res.status === 200) {
			return {data: res.data, type};
		}
		return clearStorage();
	} catch (error) {
		console.error(error);
		//return clearStorage();
	}
};

const getOrders = async ({last_id, elementToSearch, sort, sortType}) => {
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();
	const res = await axios.get(`${baseUrl}/hub/orders/${last_id}?sort=${sort}&sort_type=${sortType}${elementToSearch ? `&order_id_to_search=${elementToSearch}` : ''}`, { cancelToken: source.token });
	source.cancel ()
	if (res.status === 200) {
			return res.data
	}
	return clearStorage();
}

const getNumberProducts = async ({ sortKeys } = { }) => {

	const arrToReturn = [];
	try {
		const [
			arrivals,
			all,
			to_sell_V0070_V0471,
			to_sell_V0085_V0472,
			to_diag,
			received,
			waiting,
			toSendBack,
			//done,
			packages
		] = await Promise.all([
			getProducts({ type: 'arrivals', count: true }),
			getProducts({ type: 'all', count: true }),
			getProducts({ type: 'to_sell_V0070_V0471', count: true }),
			getProducts({ type: 'to_sell_V0085_V0472', count: true }),
			getProducts({ type: 'to_diag', count: true }),
			getProducts({ type: 'received', count: true }),
			getProducts({ type: 'problems', count: true }),
			getProducts({ type: 'action_necessary', count: true }),
			//const done = await getProducts({ type: 'done', count: true });
			getExpeditions()
		])
		// const arrivals = await getProducts({ type: 'arrivals', count: true });
		// const to_sell_V0070_V0471 = await getProducts({ type: 'to_sell_V0070_V0471', count: true });
		// const to_sell_V0085_V0472 = await getProducts({ type: 'to_sell_V0085_V0472', count: true });
		// const to_diag = await getProducts({ type: 'to_diag', count: true });
		// const delivered = await getProducts({ type: 'received', count: true });
		// const waiting = await getProducts({ type: 'problems', count: true });
		// const toSendBack = await getProducts({ type: 'to_return', count: true });
		// //const done = await getProducts({ type: 'done', count: true });
		// const packages = await getExpeditions();

		// if (toSendBack && waiting) {
		if (all) {
			arrToReturn.push(
				{
					id: '1',
					value: 'Arrivages',
					number: arrivals.products.length,
					color: '#c7c7e2',
					// blacklist: [388, 21170, 824, 325]
					blacklist: [388, 21170, 824]
				},
				{
					id: '2',
					value: 'Livrés',
					number: received.products.length,
					color: '#bce6bc',
					// blacklist: [388, 21170, 824, 325]
					blacklist: [388, 21170, 824]
				},
				{
					id: '2',
					value: 'Tous les produits',
					number: all.products.length,
					color: '#e45d5d',
					// blacklist: [388, 21170, 824, 325]
					blacklist: [388, 21170, 824]
				},
				{
					id: '3',
					value: 'En attente',
					number: waiting.products.length,
					color: '#e45d5d',
					// blacklist: [388, 21170, 824, 325]
					blacklist: [388, 21170, 824]
				},
				{
					id: '6',
					value: 'À tester',
					number: to_diag.products.length,
					color: '#e45d5d',
					// whitelist: [388, 21170, 824, 325]
					whitelist: [388, 21170, 824]
				},
				{
					id: '7',
					value: 'À vendre - V0070/V0471',
					number: to_sell_V0070_V0471.products.length,
					color: '#c7c7e2',
					// whitelist: [388, 21170, 824, 325]
					whitelist: [388, 21170, 824]
				},
				{
					id: '8',
					value: 'À vendre - V0085/V0472',
					number: to_sell_V0085_V0472.products.length,
					color: '#bce6bc',
					// whitelist: [388, 21170, 824, 325]
					whitelist: [388, 21170, 824]
				},
				{
					id: '4',
					value: 'Action nécessaire ADN',
					number: toSendBack.products.length,
					color: '#e8e8a7',
					// whitelist: [388, 21170, 824, 325]
					whitelist: [388, 21170, 824]
				},
				// {
				// 	id: '5',
				// 	value: 'Terminés',
				// 	number:  0,//done.products.length,
				// 	color: '#8cb4ff'
				// },
				{
					id: '9',
					value: 'Reception de colis',
					number: packages.length,
					displayDashboard: false,
					color: 'green'
				}
			);
			return arrToReturn;
		}

		return arrToReturn.push({
			value: 'no Products',
			number: 0
		});
	} catch (error) {
		console.error({ error });
		//clearStorage();
		return [];
	}
};

export default {
	jsonToCsv,
	generateCSV,
	clearStorage,
	ping,
	getProducts,
	getNumberProducts,
	getOrders
};
