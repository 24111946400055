import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Modal from '../components/Modal';
import NavBar from '../components/Navbar';
import { Wrapper } from '../globalStyles';
import NavMenu from '../components/NavMenu';
import { Header, Form, Button, Alert, Table, Card, Tag, StampCard } from 'tabler-react';
import API from '../controllers/actions/expeditionsActions/API'
import { getProduct } from '../controllers/actions/productsActions/API'

const recipientInformations = {
    ADN: {
        address: "2 rue Roberval, 72000 Le Mans",
        option: "ADN - Le Mans"
    },
    NGR: {
        address: "8 Cité d'Antin, 75009 Paris",
        option: "NGR - Paris"
    },
    ACTEON: {
        address: "Hôtel des Ventes de Senlis, 63 Rue du Faubourg Saint-Martin, 60300 Senlis",
        option: "ACTEON - Senlis"
    }
}

const StyledDisplayExpeditionsContainer = styled.div`
	box-sizing: border-box;
    height: calc(100% - 50px);
	overflow: scroll;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2em;
	width: 100%;
`;

const ContainerInput = styled.div`
    width: 300px;
    padding: 1em 0em;
`;


const GenerateExpeditions = ({ template, setUserToDeconnect }) => {
    const el_input_search = useRef (null)
    const [recipient, setRecipient] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [ready, setReady] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [recapExpedition, setRecapExpedition] = useState(null)
    const [messages, setMessages] = useState ({
        recipient: {
            content: ''
        },
        search: {
            content: ''
        },
        generate: {
            content: ''
        }
    })

    useEffect (() => {
        let element = el_input_search.current
        let triggered_function = () => setSearchValue (v => `${v.trim()} `)

        if (element) element.addEventListener ('paste', triggered_function)
        setReady(true)
        // permet de déconnecter le user au bout d'un jour et de rediriger vers login
        const date_login = localStorage.getItem('date_login');

        if (moment(date_login).add(1, 'days')._d > moment()._d) {
            setUserToDeconnect(false)
        } else {
            localStorage.clear()
            setUserToDeconnect(true)
        }
        return () => element && element.removeEventListener ('paste', triggered_function)
    }, [el_input_search.current]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        if (e.target.name === 'recipient') {
            if (messages.recipient.content) {
                setMessages((message) => {
                    return {
                        ...message,
                        recipient: {
                            content: ''
                        }
                    }
                })
            }
            setRecipient(e.target.value)
        }
        if (e.target.name === "search") {
            if (messages.search.content) {
                setMessages((message) => {
                    return {
                        ...message,
                        search: {
                            content: ''
                        }
                    }
                })
            }
            setSearchValue (e.target.value)
        }
    }
    const handleClick = async () => {
        if (is_input_errors()) return
        let list_of_skus = searchValue.trim()
                                .replace(/ |\//g, ';')
                                .split (';')
                                .filter (Boolean)
        // Remove duplicate SKU
        list_of_skus = Array.from( new Set(list_of_skus) )

        const products = await Promise.all(
            list_of_skus.map(async (sku) => {
                let data = await getProduct({ SKU: sku })
                return data;
            })
        )

        setRecapExpedition ({
            list_of_skus: products.filter(Boolean).map((p) => { return { sku: p.device.sku, model: p.device.model } })
        })
        // Choose recipient NGR, ADN
        //Create Group and generatePartnersExpeditions
    }
    const handleOnKeyUp = (e) => e.key ==='Enter' && handleClick ()

    const handleGenerateExpeditions = async () => {
        setIsLoading(true)
        setMessages((messages) => {
            return {
                ...messages,
                generate: { content: '' }
            }
        })
        // Generate expeditions
        const { data, source } = await API.generateExpeditions ({ list_of_skus: recapExpedition.list_of_skus.map (p => p.sku), recipient })
        source.cancel ()
        setIsLoading (false)
        if (!data) setMessages((messages) => {
            return {
                ...messages,
                generate: {
                    content: 'Il n\'y a plus d\'étiquette de disponibles'
                }
            }
        })
        else {
            setMessages((messages) => {
                return {
                    ...messages,
                    generate: {
                        content: 'Expedition créé',
                        type: 'success'
                    }
                }
            })
        }
    }

    const handleGenerateListing = async () => {
        const { data, source } = await API.generateListing ({ list_of_skus: recapExpedition.list_of_skus.map (p => p.sku) })
        source.cancel ()

        if (!data) {
            setMessages((messages) => {
                return {
                    ...messages,
                    generate: {
                        content: 'Aucun groupe reconnu. Merci de créer une etiquette avant de generer le bon de livraison'
                    }
                }
            })
        }
    }

    const is_input_errors = () => {
        let is_errors = false;
        if (!recipient) {
            setMessages((message) => {
                return {
                    ...message,
                    recipient: {
                        content:'Merci de preciser le destinataire'
                    }
                }
            })
            is_errors = true
        }
        if (!searchValue) {
            setMessages((message) => {
                return {
                    ...message,
                    search: {
                        content:'Merci de préciser les SKUs concernés par l\'expédition, separés par des espaces'
                    }
                }
            })
            is_errors = true
        }
        return is_errors
    }

    const generateHeader = () => {
        return (
            <>
                <Table.Row>
                    <Table.ColHeader>SKU</Table.ColHeader>
                    <Table.ColHeader>Modèle</Table.ColHeader>
                </Table.Row>
            </>
        )
    }
    const generateBody = () => {
        return recapExpedition.list_of_skus.map ((product) => {
            return (
                <Table.Row key={product.sku}>
                    <Table.Col>
                        <Tag color="yellow">{product.sku}</Tag>
                    </Table.Col>
                    <Table.Col>{product.model}</Table.Col>
                </Table.Row>
            )
        })
    }

    return (
        <>
			<Modal show={showModal} setShowModal={setShowModal} template='askSupervisor' />
			<NavBar />
			<Wrapper>
				<NavMenu page={template} />
                {ready ?
                    <StyledDisplayExpeditionsContainer>
                        <Header.H1>Expeditions</Header.H1>
                        <ContainerInput>
                            <Form.InputGroup >
                                <Form.Group>
                                    <Form.Label>Destinataire</Form.Label>
                                    <Form.Select onChange={handleChange} name="recipient">
                                        <option value="-">-</option>
                                        <option value="NGR">{recipientInformations.NGR.option}</option>
                                        <option value="ADN">{recipientInformations.ADN.option}</option>
                                        <option value="ACTEON">{recipientInformations.ACTEON.option}</option>
                                    </Form.Select>
                                </Form.Group>
                                {messages.recipient.content ? <Alert type="danger">{messages.recipient.content}</Alert> : null}

                                <Form.Group label="Liste de SKU">
                                    <div style={{display: 'flex', width: 'calc(100vw - 300px)'}} id="input_search_container" ref={el_input_search}>
                                        <Form.Input id="input_search" autoFocus name="search" type='input' onChange={handleChange} value={searchValue} style={{width: '300px'}} onKeyUp={handleOnKeyUp}/>
                                        <Button
                                        color='primary'
                                        onClick={handleClick}
                                        className="button-form-search"
                                        loading={isLoading}>
                                            OK
                                        </Button>
                                    </div>
                                </Form.Group>
                                {messages.search.content ? <Alert type="danger">{messages.search.content}</Alert> : null}
                            </Form.InputGroup>
                        </ContainerInput>
                        {recapExpedition && recipient ?
                            <>
                                <Header.H2>Récapitulatif de l'expedition</Header.H2>
                                <StampCard header={recipient} footer={recipientInformations[recipient].address} color="blue" icon="truck" />
                                <Card>
                                    <Table className="card-table table-vcenter">
                                        <Table.Header>{generateHeader ()}</Table.Header>
                                        <Table.Body>{generateBody ()}</Table.Body>
                                    </Table>
                                </Card>
                                <Button.List>
                                    <Button color='primary' onClick={handleGenerateExpeditions} loading={isLoading} >Générer étiquette</Button>
                                    <Button color="primary" onClick={handleGenerateListing}>Bon de livraison</Button>
                                </Button.List>
                                {messages.generate.content ?
                                    <div style={{marginTop: '1em'}}>
                                        <Alert type={messages.generate.type || "danger"}>{messages.generate.content}</Alert>
                                    </div>
                                : null}
                            </>
                        : null}
                    </StyledDisplayExpeditionsContainer>
                : null}
			</Wrapper>
		</>
    );
}

export default GenerateExpeditions;