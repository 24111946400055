/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import axios from 'axios';
// import FileSaver from 'file-saver';
// import JSZip from 'jszip';
import config from '../../../config/endpoints.json';
import API from '../globalActions/API';
// import PDFJS from 'pdfjs-dist/webpack';

const env = process.env.REACT_APP_TEST || process.env.NODE_ENV || 'development';

const baseUrl = config[env].baseAPI;

axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('hubToken')}`;

export const getProduct = async ({ SKU, return_unfound_product = false }) => {
	const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
	const res = await axios.get(`${baseUrl}/hub/products/${SKU}`, { cancelToken: source.token });
	source.cancel ()
	if (res.status === 200) {
		return res.data;
	}
	return return_unfound_product ? null : API.clearStorage();
};

const productNotConform = async ({ SKU, inputs, radioInputSelected }) => {
	let inputValue = inputs.informations || null
	const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/not-conform`, {
		inputValue,
		radioInputSelected
	}, { cancelToken: source.token });
	source.cancel()
	if (res.data.success) {
		return { message: `✅ Le produit ${SKU} a bien été indiqué comme non conforme` }
	}
	return false;
};

const productConform = async ({ SKU, list = false }) => {
	const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/conform`, {}, { cancelToken: source.token });
	source.cancel()
	if (res.data.success) {
		return { message: list ? `✅ Les produits ont bien été indiqués comme conformes` : `✅ Le produit ${SKU} a bien été indiqué comme conforme`}
	}
	// if (res.data.error) {
	// 	return { errorMessage: list ? `❌ Un des produits a déjà été indiqué comme conforme` : `❌ Le produit ${SKU} a déjà été indiqué comme conforme`}
	// }
	if (res.data.error_not_exist) {
		return { errorMessage: res.data.error_not_exist}
	}
	if (res.data.error_already_conformed) {
		return { errorMessage: res.data.error_already_conformed}
	}
	return false;
};

const updateAgainStatus = async ({ buttonClicked, SKU }) => {
	const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/update_again_status`, {buttonClicked}, { cancelToken: source.token });
	source.cancel()
	if (res.data.success) {
		return { message: `✅ Le produit ${SKU} a bien été mis à jour.` }
	}
	return false;
}

const productsConform = async (skus) => {
	try {
		skus = skus.inputs.skus_listing.split('\n').map(sku => sku.trim()).filter(el => el.length)
		// const regex = /^\d{5}$/
		const regex = /^\d{4,7}$|^\d{4,7}_B$/
		let arrErrorsSkus = []
		skus.map((sku) => {
			if (!regex.test(sku)) {
				return arrErrorsSkus.push({sku});
			}
			return null;
		})
		if (arrErrorsSkus.length) {
			return { errorMessage: `❌ Les produits ${arrErrorsSkus.map(sku => (` ${sku.sku}`))} ne sont pas dans le bon format.` }
		}
		return await Promise.all(
			skus.map((sku) => productConform({ SKU: sku, list: true }))
		);
	} catch (error) {
		console.error(error);
		return error;
	}
};

const createLotSKUs = async (skus) => {
	const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
	try {
		let skus_list = skus.inputs.skus_listing_lot.split('\n').map(sku => sku.trim()).filter(el => el.length)
		// const regex = /^\d{5}$/
		const regex = /^\d{4,7}$|^\d{4,7}_B$/

		let arrErrorsSkus = []
		skus_list.map((sku) => {
			if (!regex.test(sku)) {
				return arrErrorsSkus.push({sku});
			}
			return null;
		})
		if (arrErrorsSkus.length) {
			return { errorMessage: `❌ Les produits ${arrErrorsSkus.map(sku => (` ${sku.sku}`))} ne sont pas dans le bon format.` }
		}
		const res = await axios.post(`${baseUrl}/hub/products/${skus.inputs.lot_name}/create_lot`, {
			skus_list
		}, { cancelToken: source.token });

		if (res.data.success) {
			return { message: `✅ Le lot ${skus.inputs.lot_name} a bien été créé`}
		}
		if (res.data.error) {
			return { errorMessage:`❌ ${res.data.error}`}
		}
		return false;

	} catch (error) {
		console.error(error);
		return error;
	}
}

const noEligibleProduct = async ({ SKU, buttonClicked } = { }) => {
	if (!SKU || !buttonClicked) { return; }
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/ineligible`, {
		sku: SKU,
		reason: {
			HS: 'hs',
			'Hors catalogue': 'hors_catalogue'
		}[buttonClicked]
	}, { cancelToken: source.token });
	source.cancel ()
	if (res.data.success) {
		return res.data;
	}
	return null;
};

const blockedProduct = async ({ SKU, buttonClicked } = { }) => {
	if (!SKU || !buttonClicked) { return; }
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/blocked`, {
		sku: SKU,
		reason: {
			iCloud: 'icloud',
			'Mot de passe': 'password',
			Filevault: 'filevault'
		}[buttonClicked]
	}, { cancelToken: source.token});
	if (res.data.success) {
		return res.data;
	}
	return null;
};

const unReceivedProduct = async ({ SKU } = { }) => {
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/unreceived`, {
		sku: SKU
	}, { cancelToken: source.token });
	source.cancel()
	if (res.data.success) {
		return { message: `✅ Le produit ${SKU} a bien été indiqué comme non reçu.` }
	}
	return false;
};

const unReceivedProducts = async (skus) => {
	try {
		await Promise.all(
			skus.map((sku) => unReceivedProduct({ SKU: sku.SKU }))
		);
	} catch (error) {
		console.error(error);
		return error;
	}
};

// const getBase64 = async (url) => {
// if (!url) { return null; }
// const img = await axios.get(`${url}`, {
// responseType: 'blob'
// });
// return img.data;
// };

const getInvoices = async ({ SKU } = { }) => {
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.get(`${baseUrl}/hub/products/${SKU}/accounting`, { cancelToken: source.token });
	source.cancel()
	if (res.status !== 200) { return; }
	if (res.data.invoices.length) {
		const a = document.createElement('a');
		const linkText = document.createTextNode('my title text');
		a.appendChild(linkText);
		a.title = 'my title text';
		a.href = res.data.invoices[0].link;
		a.target = '_blank';
		a.click();
	}
};

const getCNI = async ({ SKU } = { }) => {
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.get(`${baseUrl}/hub/products/${SKU}/accounting`, { cancelToken: source.token });
	source.cancel ()
	if (res.status !== 200) { return; }
	if (res.data.invoices.length) {
		const a = document.createElement('a');
		const linkText = document.createTextNode('my title text');
		a.appendChild(linkText);
		a.title = 'my title text';
		a.href = res.data.user_documents.identity[0];
		a.target = '_blank';
		a.click();
	}
};

const getBDC = async ({ SKU } = { }) => {
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.get(`${baseUrl}/hub/products/${SKU}/accounting`, { cancelToken: source.token });
	source.cancel()
	if (res.status !== 200) { return; }
	if (res.data.invoices.length) {
		const a = document.createElement('a');
		const linkText = document.createTextNode('my title text');
		a.appendChild(linkText);
		a.title = 'my title text';
		a.href = res.data.product_documents.aol[0] || res.data.product_documents.pending_aol[0];
		a.target = '_blank';
		a.click();
	}
};

// const getProductAccountingElements = async ({ SKU } = { }) => {
// const res = await axios.get(`${baseUrl}/hub/products/${SKU}/accounting`);
// if (res.status !== 200) {
// console.log('Status not 200, abort');
// return;
// }
// console.log('RESULT from API', res);

// const zip = new JSZip();
// if (res.data.product_documents) {
// const data = await getBase64((res.data.product_documents.aol[0] || res.data.product_documents.pending_aol[0])); // .replace('https://api.hellozack.fr', 'http://localhost:5000');
// zip.folder(`element_comptable_${SKU}`).file('aol.pdf', data, { base64: true });
// }
// // if (res.data.invoices.length) {
// // const data = await getBase64(`${baseUrl}/tools/bypass_cors?url=https://hellozack.harvestapp.com/client/invoices/9db225e1827873b8e2467e043be0d29ce6396870.pdf`);
// // zip.folder(`element_comptable_${SKU}`).file('facture.pdf', data, { base64: false });
// // zip.generateAsync({ type: 'blob' }).then((content) => {
// // FileSaver.saveAs(content, 'ZipFileName.zip');
// // });
// // }
// if (res.data.user_documents) {
// console.log('oui');
// const img = new Image();
// img.crossOrigin = 'Anonymous';
// img.src = res.data.user_documents.identity[0];
// const extension = img.src.split('.').reverse()[0];
// if (extension === 'pdf') {
// let x = PDFJS.getDocument(`${baseUrl}/tools/bypass_cors?url=${img.src}`);
// x.promise.then((data) => {
// let p1 = data.getPage(1);
// zip.folder(`element_comptable_${SKU}`).file('cni.pdf', p1);
// zip.generateAsync({ type: 'blob' }).then((content) => {
// FileSaver.saveAs(content, `elements_${SKU}`);
// });
// })
// // const data = await getBase64(img.src, extension);
// // console.log('do it dude');
// // const data = await getBase64(`${baseUrl}/tools/bypass_cors?url=${img.src}`);
// // console.log('DATA', data);


// // const data = await getBase64(img.src); // .replace('https://api.hellozack.fr', 'http://localhost:5000');
// // zip.folder(`element_comptable_${SKU}`).file('cni.pdf', data, { bynary: true });
// // zip.generateAsync({ type: 'blob' }).then((content) => {
// // FileSaver.saveAs(content, `elements_${SKU}`);
// // });
// }
// if (['jpg', 'png'].includes(extension)) {
// img.onload = function () {
// console.log('onload');
// let canvas = document.createElement('CANVAS');
// const ctx = canvas.getContext('2d');
// canvas.height = img.height;
// canvas.width = img.width;
// ctx.drawImage(this, 0, 0);
// ctx.enabled = false;
// const dataURL = canvas.toDataURL('Canvas');
// canvas = null;
// const base64String = dataURL.replace(`data:image/${extension};base64,`, '');
// zip.folder(`element_comptable_${SKU}`).file(`cni.${extension}`, base64String, { base64: true });
// // if (doc[0] === 'invoices' && doc[1].length) {
// // zip.file('facture.pdf', base64String, { base64: true });
// // }
// zip.generateAsync({ type: 'blob' }).then((content) => {
// FileSaver.saveAs(content, `elements_${SKU}`);
// });
// };
// }
// console.log('src', img.src);
// }
// };

// const getProductsAccountingElements = async (skus) => {
// try {
// await Promise.all(
// skus.map((sku) => getProductAccountingElements({ SKU: sku.SKU }))
// );
// } catch (error) {
// console.error(error);
// return error;
// }
// };

const productRetrieved = async ({ SKU }) => {
	const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
	const res = await axios.post(`${baseUrl}/hub/products/${SKU}/retrieved`, { cancelToken: source.token });
	source.cancel ()
	if (res.data.success) {
		return res.data;
	}
	return null;
};

const productsRetrieved = async (skus) => {
	try {
		await Promise.all(
			skus.map((sku) => productRetrieved({ SKU: sku.SKU }))
		);
	} catch (error) {
		console.error(error);
	}
};

export default {
	productRetrieved,
	productsRetrieved,
	getProduct,
	productNotConform,
	productConform,
	productsConform,
	noEligibleProduct,
	blockedProduct,
	unReceivedProduct,
	unReceivedProducts,
	// getProductAccountingElements,
	// getProductsAccountingElements,
	getInvoices,
	getBDC,
	getCNI,
	updateAgainStatus,
	createLotSKUs
};
