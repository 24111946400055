import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UserProvider } from './controllers/jwt/getHubberData';
import { PlatFormProvider } from './components/contexts/PlatformContext';


ReactDOM.render((
	<UserProvider>
		<PlatFormProvider>
			<App />
		</PlatFormProvider>
	</UserProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
