import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'tabler-react'

const ContainerNavButtons = ({ actions, handleClick, platform }) => {
	const generateButtons = useCallback(() => {
		return actions.map((button) => {
			return (
				<Nav.Item key={button.id} to={button.url} onClick={() => handleClick(button.url)}>
					{button.title}
				</Nav.Item>
			);
		});
	}, [actions]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Nav>
			{generateButtons()}
		</Nav>
	);
};

ContainerNavButtons.propTypes = {
	platform: PropTypes.string,
	actions: PropTypes.array,
	handleClick: PropTypes.func
};

export default React.memo(ContainerNavButtons);
