import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo_zack.png';
// import burger from '../../assets/button_menu.svg';
import user from '../../assets/user-account.svg';
import logout from '../../assets/power-button.svg';

import { StyleContainerNavBar } from './style';
import ContainerNavButtons from './containers/ContainerNavButtons/index';
import { PlatformContext } from '../contexts/PlatformContext';

const Img = styled.img`
	max-width: 30px;
	max-height: 30px;
	margin-left: 1em;
	cursor: pointer;
`;

const Span = styled.span`
	padding: 8px;
  margin: 0px;
	color: ${(props) => props.theme.titleColor};
	width: 100%;
	font-weight: bold;
`;

// const Burger = styled.img`
// 	max-width: 30px;
// 	max-height: 30px;
// 	margin-left: 1em;
// 	margin-right: 1em;
// 	cursor: pointer;
// `;

const actionsSchemas = [
	{
		id: 1,
		title: 'Mon compte',
		url: '/account',
		mobilePicto: user,
		restricted: false
	},
	{
		id: 2,
		title: 'Deconnexion',
		url: '/login',
		mobilePicto: logout,
		restricted: false
	}
];

const NavBar = () => {
	const [platform] = useContext(PlatformContext);

	const handleClick = useCallback((url) => {
		if (url === '/login') {
			localStorage.clear();
		}
	}, [])
	// const toggleMenu = () => setMenu(!menu);

	return (
		<StyleContainerNavBar>
			{/* {platform !== 'desktop' && <Burger onClick={toggleMenu} src={burger} alt="burger menu" />} */}
			<div>
				<Link to="/">
					<Img src={logo} alt="logo_zack" />
				</Link>
				<Span>Hub Zack</Span>
			</div>
			<div style={{ width: '250px' }}>
				<ContainerNavButtons
					platform={platform}
					actions={actionsSchemas}
					handleClick={handleClick}
				/>
			</div>
		</StyleContainerNavBar>
	);
};

export default React.memo(NavBar);
