/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledContainerNavMenu = styled.div`
	background-color: ${(props) => props.theme.whiteColor};
	position: sticky;
	width: 204px;
	min-width: 204px;
	border-right: solid 1px ${(props) => props.theme.veryDarkGrey};
	transition: 0.3s;
	display: ${(props) => props.show ? 'block' : 'none'}
	@media (max-width: 800px) {
		position: absolute;
		height: 100vh;
		z-index: 600;
	}
`;

export const StyledNumber = styled.span`
	font-weight: bold;
	position: absolute;
	right: 20px;
	color: ${(props) => props.isFocus ? 'blue' : props.theme.veryDarkGrey};
`;

export const Li = styled.div`
	${(props) => {
		if (props.flex) return `display: flex; justify-content: space-between; align-items: center;`
		return `display: block`
	}}
	width: 100%;
	padding: 8px;
  	padding-right: 0px;
	transition: 0.9s;
	position: relative;
`;

export const Ul = styled.ul`
	list-style-type: none;
	margin: 0px;
	padding-left: 15px;
	padding-top: 15px;
`;

export const Span = styled.span`
	color: ${(props) => props.isFocus ? 'blue' : props.theme.titleColor};
	font-weight: ${(props) => props.isFocus ? 'bold' : 'normal'};
`;
