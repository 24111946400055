import styled from 'styled-components';
import { FlexDivCenter } from '../../globalStyles';

export const ContainerLoaderDiv = styled(FlexDivCenter)`
	width: 100%;
	margin-left: ${props => props.marginLeft || null};
	margin-top: ${props => props.marginTop || null};
`;

export const LoaderImg = styled.img`
  width: 120px;
`;
