// const adnIds = [388, 21170, 824, 29990, 325]
const adnIds = [388, 21170, 824]

// const hubberIds = [8669, 31176]
const hubberIds = [8669]

const menuSchema = [
	// {
	// 	id: 1,
	// 	title: 'Dashboard',
	// 	url: '/',
	// 	blackList: [8669],
	// 	restricted: false
	// },
	{
		id: 4,
		title: 'Réceptions',
		url: '/expeditions',
		type: 'expeditions',
		restricted: false,
		whiteList: adnIds,
		displayNumber: true
	},
	{
		id: 5,
		title: 'Commandes en cours',
		url: '/orders',
		type: 'orders',
		restricted: false,
		whiteList: adnIds,
		displayNumber: true
	},
	{
		id: 8,
		title: 'Réceptions',
		url: '/expeditions',
		type: 'expeditions',
		restricted: false,
		whiteList: hubberIds,
		displayNumber: false
	},
	{
		id: 9,
		title: 'Créer expédition',
		url: '/to_send',
		type: 'to_send',
		restricted: false,
		whiteList: hubberIds,
		displayNumber: false
	},
	{
		id: 10,
		title: 'Expéditions',
		url: '/sent',
		type: 'sent',
		restricted: false,
		whiteList: hubberIds,
		displayNumber: false
	},
	// {
	// 	id: 11,
	// 	title: 'Inventaires',
	// 	url: '/inventories',
	// 	type: 'inventories',
	// 	restricted: false,
	// 	whiteList: hubberIds,
	// 	displayNumber: false
	// },
	{
		id: 2,
		title: 'Tous les produits',
		url: '/all',
		type: 'all',
		restricted: false,
		displayNumber: true,
		blackList: hubberIds,
		subMenu: [
			{
				id: 26,
				title: 'À tester',
				url: '/to_diag',
				type: 'to_diag',
				restricted: false,
				displayNumber: true,
				whiteList: adnIds,
			},
			{
				id: 23,
				title: 'En attente',
				url: '/problems',
				type: 'problems',
				restricted: false,
				displayNumber: true,
				blackList: hubberIds,
			},
			{
				id: 24,
				title: 'Action nécessaire ADN',
				url: '/action_necessary',
				type: 'action_necessary',
				restricted: false,
				displayNumber: true,
				blackList: hubberIds,
			},
			{
				id: 27,
				// title: 'A mettre en vente V0070',
				title: 'À vendre - V0070/V0471',
				url: '/to_sell_V0070_V0471',
				type: 'to_sell_V0070_V0471',
				restricted: false,
				displayNumber: true,
				whiteList: adnIds,
			},
			{
				id: 28,
				// title: 'A mettre en vente V0085',
				title: 'À vendre - V0085/V0472',
				url: '/to_sell_V0085_V0472',
				type: 'to_sell_V0085_V0472',
				restricted: false,
				displayNumber: true,
				whiteList: adnIds,
			},
		//	{
		//		id: 21,
		//		title: 'Arrivages',
		//		url: '/arrivals',
		//		type: 'arrivals',
		//		restricted: false,
		//		displayNumber: true,
		//		blackList: [388, 21170, 824]
		//	},
		//	{
		//		id: 22,
		//		title: 'Livrés',
		//		url: '/received',
		//		type: 'received',
		//		restricted: false,
		//		displayNumber: true,
		//		blackList: [388, 21170, 824]
		//	},
			// {
			// 	id: 25,
			// 	title: 'Terminés',
			// 	url: '/done',
			// 	restricted: false,
			// 	displayNumber: true,
			// 	blackList: [8669]
			// }
		]
	}
];

export default menuSchema;
