import React, { useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Icon, Header, Text, Form, Button } from 'tabler-react';
import { ares_password } from '../../config/env.json'

const AllWindow = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1000;
`;

const Message = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 400px;
	background-color: white;
	border-radius: 20px;
    padding: 2em 2em 2em 2em;
`;

const ContainerExitCross = styled.span`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	width: 25px;
	height: 25px;
`;


const Modal = ({ show, setShowModal, template = 'expeditions', data }) => {
    const [password, setPassword] = useState('')
    const recordInputValue = (e) => setPassword(e.target.value)
    const handleClick = () => {
        if (password === ares_password) {
            show.data.nextAction (show.data.sku)
            setShowModal({ display: false });

        }
    }
	return show.display ? {
			'askSupervisor': (
				<AllWindow>
					<Message>
						<ContainerExitCross onClick={() => setShowModal({ display: false})} >
							<Icon name="x-circle" style={{ fontSize: "1.5em", color: '#c0392b' }}/>
						</ContainerExitCross>
						<Header.H2>Cette action necessite validation:</Header.H2>
						<Text>Etes vous sur que ce produit n'est pas dans le colis ? Merci de valider avec votre superviseur.</Text>
                        <Form.InputGroup>
                            <Form.Input icon='unlock' placeholder="*****" type="password" onChange={recordInputValue} name="password" />
                            <Button color='primary'
                                onClick={handleClick}
                            >
                                OK
                            </Button>
                        </Form.InputGroup>
					</Message>
				</AllWindow>
			)
		}[template || 'askSupervisor'] : null
}

export default withRouter(Modal);