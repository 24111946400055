import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0;
		height: 100%;
    width: 100%;
    overflow-y: hidden;
    background-color: ${(props) => props.background ? props.background : 'white'}
  }
  a {
    text-decoration: none;
  }
`;

export const FlexDivCenter = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexColumn = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContainerLoader = styled.h3`
  font-size: 15px;
  font-family: ${(props) => props.theme.mainFontFamily};
  color: ${(props) => props.isLoaded ? props.theme.blueColorButton : props.theme.veryDarkGrey};
  position: absolute;
  top: 50%;
  left: 50%;
`;

